<template>
  <div class="commomTitlePage">
    {{ title }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
  },
};
</script>

<style lang="scss" scoped>
.commomTitlePage {
  color: var(--opposite-color);
  cursor: default;
  font: var(--headline-semibold-24);
  flex-basis: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  ::v-deep svg path {
    fill: var(--opposite-color);
  }
}
</style>
